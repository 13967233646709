<template>
  <div class="mt-1 mr-1 mb-5" v-if="canVerb(resource, 'index')">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
    </b-row>

    <b-collapse id="collapse-1" v-model="showCollapse1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="col-md-3">
              <base-select
                name="cod_broker"
                label="Agenzia"
                :options="agency_brokers"
                v-model="filter.byBrokerGroup.id"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="from"
                label="Registrazione Dal"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="to"
                label="Registrazione Al"
                v-model="filter.byCalendar.to"
              />
            </div>
            <!-- <div class="col-md-3">
              <base-checkbox
                name="arretrati"
                label="Includi"
                v-model="arretrati"
                :chk_val="true"
                :unchk_val="false"
                :isSwitch="true"
                groupLabel="Includi registrazioni escluse dai rendiconti precedenti"
              />
            </div> -->
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-select
                name="cod_prod"
                label="Compagnia"
                :options="companies"
                v-model="filter.byInsurer.id"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button type="submit" variant="lisaweb" size="sm"
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>
    <b-overlay center :show="isLoading" rounded="sm">
      <export-options
        :exportUrl="exportUrl"
        :repository="repository"
        :resource="resource"
        :tableRef="$refs[tableRef]"
        :filter="filter"
        :options="[
          {
            code: 'RENCOMPROV',
            label: null,
            formats: ['csv', 'pdf'],
          },
        ]"
      ></export-options>
      <!-- <button-group-table
        @csv="onExport('csv', 'RENCOMPROV')"
        :options="{
          CSV: { enabled: true },
        }"
      ></button-group-table> -->
      <agency-brokers-accounting
        :filterOn="{
          byRelations: [
            'byBookTag',
            'byInsurer',
            'byRegistry',
            'byInsuranceAncillary',
            'byVariousAccounting',
          ],
        }"
        :fields="flds"
        :repository="repository"
        :resource="resource"
        :filterName="filterName"
        :ref="tableRef"
        noActions
        includeBottomRow
        noPagination
      ></agency-brokers-accounting>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
  <div v-else class="mt-3">
    <b-icon icon="info-circle" scale="1.00"></b-icon>
    Non hai i permessi
  </div>
</template>

<script>
import AgencyBrokersAccounting from "@/components/tables/AgencyBrokersAccounting";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseIcon from "@/components/BaseIcon";
// import ButtonGroupTable from "@/components/ButtonGroupTable";
import ExportOptions from "@/components/ExportOptions";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
// import ExportsMixin from "@/mixins/ExportsMixin";
import FormMixin from "@/mixins/FormMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { mapGetters, mapActions } from "vuex";
import { toLocaleCurrency } from "@/utils/strings";
import { toLocaleDate } from "@/utils/dates";
import { prepareFilterQueryString } from "@/utils/forms";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import moment from "moment";
moment.locale("it");

export default {
  mixins: [
    CustomFiltersMixin,
    ConfirmModalMixin,
    FormMixin,
    StorageGetterMixin,
  ],
  data() {
    return {
      exportUrl: null,
      isLoading: false,
      filter: this.initFilter(),
      calculus_type: 1,
      showCollapse1: true,
      // canBrokerStatement: false,
      canShowBrokerStatement: false,
      changedIds: [],
      filterName: "filterAccountingInsurerAccounting",
      resource: "book_entries",
      repository: "book_entry",
      tableRef: "AccountingInsurerAccountingTableRef",
      fields: [],
      selectableFields: {
        0: [
          {
            key: "deferred",
            label: "Sospeso",
            sortable: false,
          },
          {
            key: "book_date",
            label: "D.Reg",
            sortable: false,
            formatter: (value) => toLocaleDate(value),
          },
          {
            key: "effective_date",
            label: "D.Eff",
            sortable: false,
            formatter: (value) => toLocaleDate(value),
          },
          // {
          //   key: "title",
          //   label: "Descrizione",
          //   sortable: false,
          // },
          {
            key: "customer",
            label: "Contraente/descrizione",
            sortable: false,
          },
          {
            key: "insurance_policy",
            label: "Polizza",
            sortable: false,
          },
          {
            key: "book_tag",
            label: "C.Doc",
            sortable: false,
          },
          {
            key: "company",
            label: "Com",
            sortable: false,
          },
          {
            key: "broker",
            label: "Prod",
            sortable: false,
          },
          {
            key: "branch_risk",
            label: "Ramo-rischio",
            sortable: false,
          },
          {
            key: "gross",
            label: "Premio",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "net",
            label: "Imp",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          //  #771 Sì, confermo che si vedono le colonne di agenzia e poi le colonne di agenzia calcolate.
          {
            key: "agency_prov_purchase",
            label: "Provv.Acq.Age",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "agency_prov_take",
            label: "Provv.Inc.Age",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "purchase_commission",
            // label: "prov calcolata acquisto",
            label: "Provv.Calcolata.Acq",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "take_commission",
            // label: "prov calcolata incasso",
            label: "Provv.Calcolata.Inc",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "delta",
            label: "Delta",
            sortable: false,
            formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
          {
            key: "tot_prod",
            label: "Tot.Prod",
            sortable: false,
            // formatter: (value) => toLocaleCurrency(value),
            tdClass: "text-right",
            thClass: "text-right",
            // thStyle: {
            //   width: "125px",
            // },
          },
        ],
        1: [
          {
            key: "id",
            label: "[ID] - Campi per modalità rendicontro Altro DA DEFINIRE",
            sortable: false,
          },
        ],
      },
      summary: {
        entrate: 0,
        sospesi: 0,
        acconti: 0,
        di_cui_disponibili: 0,
        provvigioni: 0,
        di_cui: 0,
        ritenuta: 0,
        ritenuta_perc: 0.0,
        da_liquidare: 0,
        saldo: 0,
      },
      agency_brokers: [],
    };
  },
  components: {
    BaseIcon,
    BaseSelect,
    BaseDatepicker,
    AgencyBrokersAccounting,
    // ButtonGroupTable,
    ExportOptions,
  },
  methods: {
    toLocaleCurrency,
    initFilter() {
      let init = {
        byBrokerGroup: {
          id: null,
        },
        byCalendar: {
          from: null,
          to: null,
        },
        byInsurer: {
          id: null,
        },
        byView: "book_entry",
      };
      return init;
    },
    /* drawBrokerStatement() {
      // se il prosuttore intProd non è selezionato, il bottone ufficializza è diabilitato
      // if (!this.agency_broker_id) {
      //   this.$showSnackbar({
      //     preset: "info",
      //     text: "Per poter ufficializzare il rendiconto, occorre selezionare un produttore in Intestazione documenti prodotti",
      //   });
      // }
      // 1. mettere in sola lettura il checkbox e i campi input della table
      let table = this.$refs[this.tableRef];
      // table.isTableReadonly = true;

      // ENTRATE (premio)
      this.summary.entrate = table.totalizeFields(table.items, "gross");

      // SOSPESI ( sommare tutti i gross dei dettagli con cassa sospesi (id=3) )
      // TODO: arriverà un campo che già totalizza i gross delle casse SS (id= 3)
      this.summary.sospesi = this.calculus_type
        ? this.sumSospesiSummary(table.items)
        : this.sumSospesi(table.items);

      // ACCONTI
      this.summary.acconti = this.sumPositiveAdvancePayment(table.items);

      //DI CUI ANCORA DISPONIBILI
      this.di_cui_disponibili =
        this.sumPositiveAdvancePayment(table.items) -
        this.sumNegativeAdvancePayment(table.items);

      // PROVVIGIONI (Acq. prod.	Inc. prod.)
      this.summary.provvigioni = table.totalizeFields(table.items, [
        "saler_prov_purchase",
        "saler_prov_take",
      ]);
      // DI CUI NON SOGGETTE A RITENUTA D'ACCONTO (somma Acq. prod.	e Inc. prod dove is_taxable = N)
      this.summary.di_cui = this.sumRitenute(table.items, [
        "saler_prov_purchase",
        "saler_prov_take",
      ]);
      // RITENUTA D'ACCONTO ( salesmen.attributables.????  -> es: 10.00%) provvigioni - di cui non soggette e applico la percentuale 10%
      // TODO: find the real field for ritenuta _perc!!!

      // broker.attributables.WHT

      this.summary.ritenuta = this.calculatePercentage(
        this.summary.provvigioni - this.summary.di_cui,
        this.summary.ritenuta_perc
      );

      // PROVVIGIONI DA LIQUIDARE (provvigioni - ritenuta d'acconto)
      this.summary.da_liquidare =
        this.summary.provvigioni - this.summary.ritenuta;

      // SALDO ENTRATE
      this.summary.saldo =
        this.summary.entrate -
        this.summary.acconti -
        this.summary.provvigioni +
        this.summary.ritenuta;
      // 3. mostrare 2 input:
      //   numero fattura (al momento text)
      //   periodo (al momento text)

      // 4. mostrare bottone "Ufficializza rendiconto"

      this.canShowBrokerStatement = true;
    }, */
    // getTotalizers() {
    //   return [
    //     { [this.getDictionary("premiums")]: this.summary.entrate },
    //     {
    //       [this.getDictionary("of_which_deferred_to_collect")]: this.summary
    //         .sospesi,
    //     },
    //     { [this.getDictionary("advance_payments")]: this.summary.acconti },
    //     { [this.getDictionary("commissions")]: this.summary.provvigioni },
    //     // { [this.getDictionary("of_which_fees")]: this.summary.di_cui_diritti }, // #1112
    //     { [this.getDictionary("of_which_not_taxable")]: this.summary.di_cui },
    //     { [this.getDictionary("fees")]: this.summary.di_cui_diritti }, // #1112
    //     {
    //       [`${this.getDictionary("deduction")} (${
    //         this.summary.ritenuta_perc
    //       }%)`]: this.summary.ritenuta,
    //     },
    //     {
    //       [this.getDictionary("commissions_to_liquidate")]: this.summary
    //         .da_liquidare,
    //     },
    //     { [this.getDictionary("premiums_balance")]: this.summary.saldo },
    //   ];
    // },
    sumRitenute(data, keys) {
      let hasChecks = true;
      let tot = 0;
      keys.forEach((key) => {
        let parts = key.split(".");
        tot += data
          .map((row) => {
            // somma solo se in presenza dei check, sono checkati e se ancillaries è vuoto e se various è diveso da 1
            return (!hasChecks || (hasChecks && row.check)) &&
              // !row.insurance_ancillaries.length &&
              // row.various_accountings[0].code != "FC"
              row.is_taxable.value === "N"
              ? +parts.reduce((acc, part) => acc && acc[part], row)
              : 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
    sumPositiveAdvancePayment(data) {
      let hasChecks = true;
      let tot = 0;
      data.forEach((item) => {
        if (!item.entry_details) {
          return;
        }
        tot += item.entry_details
          .map((row) => {
            return (!hasChecks || (hasChecks && item.check)) &&
              row.treasury.code == "AC" &&
              row.gross > 0
              ? row.gross
              : 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
    sumNegativeAdvancePayment(data) {
      let hasChecks = true;
      let tot = 0;
      data.forEach((item) => {
        if (!item.entry_details) {
          return;
        }
        tot += item.entry_details
          .map((row) => {
            return (!hasChecks || (hasChecks && item.check)) &&
              row.treasury.code == "AC" &&
              row.gross < 0
              ? row.gross
              : 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
    sumSospesi(data) {
      // sommare tutti i gross dei dettagli con cassa sospesi (id=3 or code=SS)
      let hasChecks = true;
      let tot = 0;
      data.forEach((item) => {
        if (!item.entry_details) {
          return;
        }
        tot += item.entry_details
          .map((row) => {
            // somma solo se in presenza dei check, sono checkati e se è una cassa sospesi
            return (!hasChecks || (hasChecks && item.check)) &&
              row.treasury.code == "SS"
              ? +row.gross
              : 0;
          })
          .reduce((sum, i) => {
            return sum + i;
          }, 0);
      });
      return tot;
    },
    sumSospesiSummary(data) {
      // sommare tutti i gross_deferred
      // REVIEW: in futuro anche questa cambierà...
      let hasChecks = true;
      let tot = 0;
      tot = data
        .map((row) => {
          // somma solo se in presenza dei check, sono checkati e se esiste gross_deferred
          return (!hasChecks || (hasChecks && row.check)) && row.gross_deferred
            ? +row.gross_deferred
            : 0;
        })
        .reduce((sum, i) => {
          return sum + i;
        }, 0);
      return tot;
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onSearch(name) {
      if (
        !this.filter.byBrokerGroup.id ||
        !this.filter.byCalendar.from ||
        !this.filter.byCalendar.to
      ) {
        let missing = {};
        missing.agenzia = !this.filter.byBrokerGroup.id;
        missing.registrazione_dal = !this.filter.byCalendar.from;
        missing.registrazione_al = !this.filter.byCalendar.to;
        let msg = `Devi selezionare ${Object.keys(missing)
          .filter((key) => missing[key])
          .join(", ")}`;
        this.$showSnackbar({
          preset: "info",
          text: msg,
          showAction: false,
        });
      } else {
        this.fetchData(name);
      }
    },
    // fetch by chunks of records (50)
    fetchData(name) {
      let allData = [];
      this.isLoading = true;
      // // some handles for the fetch call...
      // let args = { items: { handle: null, params: [] }, verb: {} };
      // // 1. items handle
      // args.items.handle = this.manipolaItems;
      // // let previous = this.filter.byCalendar.from;
      // // calculus_type = 1 (SEMPRE)
      // args.verb.name = "summary";
      // args.verb.alias = "index";
      // this.filter.byCommissionApplied = {};
      // aggiungere filtro byView

      // enable BE calculus
      // this.filter.byCommissionApplied.id = this.agency_broker_id;
      // this.filter.byBrokerGroup.id = this.agency_broker_id;
      // do not add broker to query string
      // this.filter.byBroker = {};

      let criteria = this.filter;
      // store the filter
      this.saveFilterByName({ name, criteria });
      // save for report..
      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );

      let Repo = RepositoryFactory.get(this.repository);
      let queryString = prepareFilterQueryString(criteria);
      let filterOn =
        "byBookTag&byInsurer&byRegistry&byInsuranceAncillary&byVariousAccounting";
      // WARNING: per ora NON POSSO PAGINARE le fetch, BE summary non lo supporta
      // una fetch UNICA:
      Repo.summary(`${filterOn}&${queryString}`)
        .then((response) => {
          // this.filter.byCalendar.from = previous;
          // REVIEW: quando BE manderà data [] invece che {}, ripistinare:
          allData = response.data.data;
          // !!! WARNING solo per far funzionare in attesa del BE, uso Object.values(response.data.data) !!!
          // if (Array.isArray(response.data.data)) {
          //   allData = response.data.data;
          // } else {
          //   allData = Object.values(response.data.data);
          // }
          // NOTE nel caso Calcola con Lisaweb, item non ha la proprietà "brokers", quindi metto a 0
          if (allData.length) {
            this.summary.ritenuta_perc = allData[0].brokers
              ? allData[0].brokers[0].attributables.WHT
              : 0;
          }
          // applico un pezzo di manipolaItems? teoricamente no perché ho solo mov FC
          // allData = allData.map((item) => {
          //   if (
          //     item.various_accountings &&
          //     item.various_accountings.length &&
          //     item.various_accountings[0].code === "AC"
          //   ) {
          //     item.gross *= -1;
          //   }
          //   return item;
          // });

          // console.log("allData length:", allData.length);
          // set items for tableRef
          this.$refs[this.tableRef].items = allData;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: errMsg,
          });
        });
      /*
      // !!! WARNING quando BE summary può paginare, attivare questo codice al posto di quello sopra
      // N fetch con paginazione FE
      let counter = 0;
      let page = 1;
      let records = 6;
      let promises = [];
      // count records
      Repo.summary(`${queryString}&count`)
        .then((response) => {
          counter = response.data.data.count;
          // console.log("records count:", counter);
          let loops =
            (counter - (counter % records)) / records +
            (counter % records ? 1 : 0);

          while (page <= loops) {
            // console.log(
            //   `queryString: ${filterOn}&${queryString}&perPage=${records}&page=${page}`
            // );
            promises.push(
              Repo.summary(`${filterOn}&${queryString}&perPage=${records}&page=${page}`)
            );
            page++;
          }

          Promise.all(promises).then((responses) => {
            // for (const [index, value] of responses.entries()) {
            //   console.log(`index: ${index}, items: ${value.data.data.length}`);
            //   // if (value.data.data.length) {
            //   //   // NOTE nel caso Calcola con Lisaweb, item non ha la proprietà "brokers", quindi metto a 0
            //   //   this.summary.ritenuta_perc = value.data.data[0]
            //   //     .brokers
            //   //     ? value.data.data[0].brokers[0].attributables.WHT
            //   //     : 0;
            //   // }
            //   allData = allData.concat(value.data.data);
            // }
            for (const [index, value] of responses.entries()) {
              // console.log(`index: ${index}, value: ${value.data.data}`);
              // if (value.data.data.length) {
              //   // NOTE nel caso Calcola con Lisaweb, item non ha la proprietà "brokers", quindi metto a 0
              //   this.summary.ritenuta_perc = value.data.data[0]
              //     .brokers
              //     ? value.data.data[0].brokers[0].attributables.WHT
              //     : 0;
              // }
              allData = allData.concat(value.data.data);
            }
            // applico un pezzo di manipolaItems? teoricamente no perché ho solo mov FC
            // allData = allData.map((item) => {
            //   if (
            //     item.various_accountings &&
            //     item.various_accountings.length &&
            //     item.various_accountings[0].code === "AC"
            //   ) {
            //     item.gross *= -1;
            //   }
            //   return item;
            // });

            // this.filter.byCalendar.from = previous;
            // console.log("allData length:", allData.length);
            // set items for tableRef
            this.$refs[this.tableRef].items = allData;
            this.isLoading = false;
          });
        })
        .catch((error) => {
          this.isLoading = false;
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        }); */
    },
    calculatePercentage(amount, perc) {
      return parseFloat(((amount * perc) / 100).toFixed(2));
    },
    resetPage() {
      this.changedIds = [];
      // reset selected rows
      this.$refs[this.tableRef].selectedRows = [];
      // remove all checks
      this.$refs[this.tableRef].unselectAll();
      this.$refs[this.tableRef].checkAll = false;
      // enable input fields and checkboxes
      // this.$refs[this.tableRef].isTableReadonly = false;
      // hide BrokerStatement button
      // this.canBrokerStatement = false;
      // hide BrokerStatement section
      this.canShowBrokerStatement = false;
      // reset form
      this.form.invoice_number = null;
      this.form.invoice_period = null;
    },
    onClearFilter(name) {
      // this.$nextTick(() => {
      this.resetFilter();
      this.resetPagination();
      // remove from store
      this.removeFilterByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      // clear the sortBy flag
      this.$refs[this.tableRef].sortBy = "";
      this.$refs[this.tableRef].sortDesc = false;
      // fetch data
      // // this.$refs[this.tableRef].fetch();
      // NOTE: since agency_broker_id is mandatory, cannot fetch (with no filter), but must clear items instead!
      // this.onSearch(name);
      // reset changedIds
      this.changedIds = [];
      // reset items
      this.$refs[this.tableRef].items = [];
      // reset checkAll checkbox
      // this.$refs[this.tableRef].checkAll = false;
      this.resetPage();
      // });
    },
    onReset() {
      this.resetPage();
      this.$refs[this.tableRef].selectAll();
      this.$refs[this.tableRef].checkAll = true;
      this.onSearch(this.filterName);
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("pagination", {
      removePaginationByName: "removeByName",
      savePaginationByName: "saveByName",
    }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getAgencyBrokers: "agency_brokers",
      getInsurers: "insurers",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  beforeMount() {
    /* */
    console.log("beforeMount index");
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("insurers")) {
      resources.push(
        this.$store
          .dispatch(`${path}insurers`)
          .then(() => {
            console.info(`background data "${path}insurers" fetched correctly`);
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.companies = this.getInsurers();
    }
    if (!this.hasLoadedResource("agency_brokers")) {
      resources.push(
        this.$store
          .dispatch(`${path}agency_brokers`)
          .then(() => {
            console.info(
              `background data "${path}agency_brokers" fetched correctly`
            );
          })
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.agency_brokers = this.getAgencyBrokers();
    }
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          console.log("All promises went through correctly");
          this.companies = this.getInsurers();
          this.agency_brokers = this.getAgencyBrokers();
          // do not AUTOMATIC FETCH
          this.removeFilterByName(this.filterName);
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.isLoading = false;
      // do not AUTOMATIC FETCH
      this.removeFilterByName(this.filterName);
    }
    /* */
    // this.companies = this.getInsurers();
    // this.agency_brokers = this.getAgencyBrokers();
    // do not AUTOMATIC FETCH
    // this.removeFilterByName(this.filterName);
  },
  computed: {
    flds() {
      return this.selectableFields[
        this.$store.state.auth.settings.attribute_STATMOD_value
      ];
    },
    selRows() {
      return this.$refs[this.tableRef].selectedRows;
    },
    // isUpd() {
    //   return this.isUpdating;
    // },
    // isSav() {
    //   return this.isSaving;
    // },
    // intProd() {
    //   // options per la seconda tendina dei produttori
    //   return this.getAgencyBrokers().filter((e) =>
    //     this.salesmen_ids.includes(e.value)
    //   );
    // },
    // hasItems() {
    //   return this.$refs[this.tableRef] && this.$refs[this.tableRef].items.length
    // }
  },
};
</script>
<style lang="scss" scoped>
table.totals {
  background-color: white;
  width: 100%;
  table-layout: fixed;
  th {
    text-align: center;
  }
  td {
    text-align: right;
    font-weight: bold;
  }
}
table.summary {
  width: 50%;
  border-collapse: collapse;
}
table,
td,
th {
  border: 1px solid #dee2e6;
}
table tr th,
td {
  width: 30%;
  padding: 2px 4px;
  text-align: left;
}
th[scope="row"] {
  background: orange !important;
  color: white !important;
  text-transform: uppercase;
  width: 70%;
}
th[scope="col"] {
  text-transform: uppercase;
}
</style>
